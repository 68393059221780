import { jsx, jsxs } from 'react/jsx-runtime';
import { Chip as Chip$1, Typography } from '@mui/material';
import 'react';
import '../../config.js';
import '../../types/requestTypes.js';
import '../../types/itemTypes.js';
import '../../auth/config.js';
import '../../auth/AuthProvider.js';
import '@azure/msal-react';
import 'dayjs';
import 'react-redux';
import '@testing-library/react';
import '../../themes/light.theme.js';
import '../../store/store.js';
import 'redux-persist/integration/react';
import '../../store/slices/applicationSlice.js';
import '../../store/slices/downloadFileSlice.js';
import '../../store/slices/itemModalSlice.js';
import '@pdftron/webviewer-video';
import '@pdftron/webviewer-audio';
import '../../services/requestApi.js';
import '../../services/userApi.js';
import '../../services/itemApi.js';
import '../../services/requestDetailsApi.js';
import '@pdftron/webviewer';
import 'lodash';
import { useTooltip } from '../../hooks/useTooltip.js';
import 'react-router-dom';
import '@dnd-kit/core';
import '@dnd-kit/sortable';
import '../../store/thunks/downloadFileThunk.js';
import { Box } from '../Box.js';
import { Tooltip } from '../Tooltip.js';

// general purpose multiline chip component
const Chip = ({ title, subTitle, border = true, color = 'default', variant = 'outlined', isLink, internalChip, ...props }) => {
    const { dataRef: titleRef, title: tooltipTitle } = useTooltip(title);
    const { dataRef: subTitleRef, title: tooltipTitleForSubtitle } = useTooltip(subTitle);
    const height = internalChip ? '5.25rem' : '3.25rem';
    const padding = internalChip ? '0.5rem 0.75rem' : '0 0.75rem';
    const align = internalChip ? 'start' : 'center';
    return (jsx(Chip$1, { ...props, clickable: !!props.onClick, onDelete: undefined, label: jsxs(Box, { background: 'none', gap: title && subTitle ? 'xsmall' : 'none', children: [title && (jsx(Tooltip, { title: tooltipTitle, arrow: true, children: jsx(Typography, { variant: 'body2', noWrap: true, ref: titleRef, children: title }) })), subTitle && (jsx(Tooltip, { title: tooltipTitleForSubtitle, arrow: true, children: jsx(Typography, { variant: 'body3', noWrap: true, ref: subTitleRef, children: subTitle }) })), !!internalChip && (jsx("div", { children: internalChip }))] }), className: `${isLink ? 'chip-link' : ''}`, color: color, variant: variant, style: { alignItems: align }, sx: {
            ...(title &&
                subTitle && {
                minHeight: { height },
                padding: { padding },
                ...(!border && {
                    '& > .MuiChip-icon': {
                        marginTop: '-0.75rem',
                    },
                }),
            }),
            ...(!border && {
                borderColor: 'transparent',
                padding: '0',
                paddingRight: '0.25rem',
            }),
            maxWidth: '36rem',
            ...props.sx,
        } }));
};

export { Chip };
