import { jsxs, jsx } from 'react/jsx-runtime';
import { styled, useTheme, Typography } from '@mui/material';
import { memo, useRef, useState, useEffect } from 'react';
import { useFileDetails } from '../hooks/useFileDetails.js';
import '../services/itemApi.js';
import { useIcon } from '../hooks/useIcon.js';
import '../services/requestApi.js';
import '../services/userApi.js';
import '../services/requestDetailsApi.js';
import '../auth/config.js';
import '../config.js';
import '../auth/AuthProvider.js';
import '@azure/msal-react';
import '../types/requestTypes.js';
import { ItemManagementStatus } from '../types/itemTypes.js';
import 'dayjs';
import 'lodash';
import { useAppDispatch } from '../store/store.js';
import 'react-redux';
import 'redux-persist/integration/react';
import '../store/slices/applicationSlice.js';
import '../store/slices/downloadFileSlice.js';
import { setCurrentItem } from '../store/slices/itemModalSlice.js';
import 'react-router-dom';
import '@dnd-kit/core';
import '@dnd-kit/sortable';
import '../store/thunks/downloadFileThunk.js';
import { Box } from './Box.js';
import { Alert } from './alert/Alert.js';
import { Open16Regular, Info20Regular, DocumentBorder20Regular } from '@fluentui/react-icons';
import './item/ItemBusinessTypePanel.js';
import { ItemEmptyState } from './item/ItemEmptyState.js';
import './item/ItemGovernancePanel.js';
import '@testing-library/react';
import '../themes/light.theme.js';
import { Button } from './button/Button.js';
import './chip/ItemManagementStatusChip.js';
import './chip/PerspectiveClassChip.js';
import './item/type/ItemTypeEmailPanel.js';

const StyledImage = styled('img') ``;
const FileViewer = ({ id, itemDetails, rowData, setPreviewState, selectedSnapshot, onSnapshotOpen, isSnapshotDetail }) => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const viewer = useRef(null);
    const iframeRef = useRef(null);
    const [alertError, setAlertOpen] = useState(false);
    const typeDefImageId = itemDetails?.TypeDef?._ImageId;
    const itemImageId = itemDetails?._ImageId;
    const referenceType = itemDetails?.TypeDef?.Name;
    const isSnapshot = isSnapshotDetail || !!selectedSnapshot;
    const { iconDetails } = useIcon({
        imageId: itemImageId ?? typeDefImageId,
    });
    const { fileDetails, htmlData, docBlobUrl, pdfJsonBlobUrl, error } = useFileDetails({
        id,
        item: itemDetails,
        viewer,
        rowData,
        snapshotVersion: selectedSnapshot?.['RelatedObjId'],
        snapshotContentId: selectedSnapshot?.['ContentId'],
        setAlertOpen,
        isSnapshot
    });
    useEffect(() => {
        !!setPreviewState &&
            setPreviewState(!!fileDetails || !!htmlData || !!docBlobUrl || !!pdfJsonBlobUrl);
    }, [fileDetails, htmlData, docBlobUrl, pdfJsonBlobUrl, id]);
    useEffect(() => {
        if (error && itemDetails.Status !== ItemManagementStatus.Destroyed) {
            setAlertOpen(true);
        }
    }, [error]);
    const handleOpenSnapshot = () => {
        onSnapshotOpen();
        dispatch(setCurrentItem({ id: selectedSnapshot.RelatedObjId, itemId: id, name: selectedSnapshot?.DisplayName, isSnapshot: true, initialTab: 'snapshotDetails' }));
    };
    const renderInfoPanel = () => {
        if (!fileDetails && !htmlData && !docBlobUrl && !pdfJsonBlobUrl && !error && !selectedSnapshot?._Display)
            return null;
        let displayTitle = '';
        if (selectedSnapshot?._Display && !isSnapshotDetail)
            displayTitle = selectedSnapshot?.DisplayName;
        else if (htmlData && referenceType === 'PostBasedConversation')
            displayTitle = 'Conversation';
        else
            displayTitle = itemDetails._Display;
        return (jsxs(Box, { width: '100%', direction: 'row', alignItems: 'center', background: 'light', style: {
                height: '3rem',
                backgroundColor: theme.palette.background.paper,
                ...!isSnapshotDetail && ({
                    justifyContent: 'space-between'
                }),
            }, children: [jsxs("div", { style: { marginLeft: '1.5rem' }, children: [iconDetails && fileDetails ? (jsx(StyledImage, { src: `${process.env.REACT_APP_ENC_IMC_URL}${iconDetails?.Url}`, alt: iconDetails?.AltText, style: { height: '1.5rem' } })) : null, jsx(Typography, { variant: 'h5', children: displayTitle })] }), !isSnapshotDetail && (jsxs("div", { style: { marginRight: '1.5rem', gap: '0.5rem' }, children: [jsx(Button, { startIcon: jsx(Open16Regular, {}), variant: 'text', size: 'medium', sx: { padding: '0.5rem 1rem !important' }, onClick: handleOpenSnapshot, disabled: !selectedSnapshot?._Display, children: "Open Snapshot" }), jsx(Button, { startIcon: jsx(Info20Regular, {}), variant: 'text', size: 'medium', sx: {
                                padding: '0.5rem !important',
                                minWidth: 0,
                                width: '2.5rem',
                                'span': {
                                    margin: 0
                                }
                            }, disabled: !selectedSnapshot?._Display, tooltip: 'Info' }), jsx(Button, { startIcon: jsx(DocumentBorder20Regular, {}), variant: 'text', size: 'medium', sx: {
                                padding: '0.5rem !important',
                                minWidth: 0,
                                width: '2.5rem',
                                'span': {
                                    margin: 0
                                }
                            }, disabled: !selectedSnapshot?._Display, tooltip: 'Preview' })] }))] }));
    };
    const renderContentViewer = () => {
        let documentViewer = '';
        if (fileDetails || htmlData || docBlobUrl || pdfJsonBlobUrl) {
            if (fileDetails) {
                documentViewer = (jsx("div", { ref: viewer, style: { height: '100%', width: '100%' } }));
            }
            else if (htmlData) {
                documentViewer = (jsx("iframe", { title: 'Preview', ref: iframeRef, srcDoc: htmlData, style: { width: '100%', height: '100%', border: 'none' } }));
            }
            else if (docBlobUrl) {
                documentViewer = (jsx("div", { ref: viewer, style: { width: '100%', height: '100%' } }));
            }
            else if (pdfJsonBlobUrl) {
                documentViewer = (jsx("iframe", { title: 'Eigen Preview', src: pdfJsonBlobUrl, style: { width: '100%', height: 'calc(100% - 3.5rem)', border: 'none' } }));
            }
        }
        else if (error) {
            documentViewer = (jsxs("div", { style: { width: '100%', height: '100%', border: 'none' }, children: [jsx(Alert, { alertType: 'warning', message: error, open: alertError, onClose: () => {
                            setAlertOpen(false);
                        }, autoHideDuration: 10000 }), jsx(ItemEmptyState, { repositoryUrl: itemDetails.RepositoryUrl })] }));
        }
        else {
            documentViewer = '';
        }
        return documentViewer;
    };
    return (jsxs("div", { style: { width: '100%', height: '100%', border: 'none' }, children: [renderInfoPanel(), renderContentViewer()] }));
};
const MemoizedFileViewer = memo(FileViewer);

export { FileViewer, MemoizedFileViewer };
