import { jsxs, jsx } from 'react/jsx-runtime';
import { Box } from '../Box.js';
import { useTheme, Dialog, DialogTitle, DialogContent, Typography, DialogActions } from '@mui/material';
import { useState } from 'react';
import { Button } from '../button/Button.js';
import { Edit20Regular } from '@fluentui/react-icons';
import '../../config.js';
import '../../types/requestTypes.js';
import '../../types/itemTypes.js';
import '../../auth/config.js';
import '../../auth/AuthProvider.js';
import '@azure/msal-react';
import 'dayjs';
import 'react-redux';
import '@testing-library/react';
import '../../themes/light.theme.js';
import '../../store/store.js';
import 'redux-persist/integration/react';
import '../../store/slices/applicationSlice.js';
import '../../store/slices/downloadFileSlice.js';
import '../../store/slices/itemModalSlice.js';
import '@pdftron/webviewer-video';
import '@pdftron/webviewer-audio';
import '../../services/requestApi.js';
import '../../services/userApi.js';
import { useGetPerspectiveClassesQuery } from '../../services/itemApi.js';
import '../../services/requestDetailsApi.js';
import '@pdftron/webviewer';
import { useIcon } from '../../hooks/useIcon.js';
import 'lodash';
import 'react-router-dom';
import '@dnd-kit/core';
import '@dnd-kit/sortable';
import '../../store/thunks/downloadFileThunk.js';
import { PerspectiveClassField } from './components/item/PerspectiveClassField.js';
import { getPerspectiveClassValues } from './components/item/customTypeUtils.js';

const EditPerspectiveClassDialog = ({ open, title, onSave, onClose, isLoading = false, fieldLabel, propertyName, propertyValue, propertyDetails, }) => {
    const theme = useTheme();
    const [selectedPerspectiveClass, setSelectedPerspectiveClass] = useState(propertyValue);
    const imageId = selectedPerspectiveClass?._ImageId;
    const { iconDetails } = useIcon({
        imageId,
    });
    const { data } = useGetPerspectiveClassesQuery({});
    const propTypeDefId = propertyDetails?.PropTypeDefId;
    const currentPerspectiveClass = selectedPerspectiveClass?._Display;
    const perspectiveClasses = getPerspectiveClassValues(data, propTypeDefId, currentPerspectiveClass);
    const handleSelect = (perspectiveClass) => {
        setSelectedPerspectiveClass(perspectiveClass);
    };
    const handleDelete = () => {
        setSelectedPerspectiveClass(null);
    };
    return (jsxs(Dialog, { open: open, PaperProps: {
            sx: {
                width: '100%',
                height: '100%',
                maxWidth: '47.5rem',
                maxHeight: '42.438rem',
            },
        }, children: [jsxs(DialogTitle, { children: [jsx(Edit20Regular, {}), title] }), jsx(DialogContent, { children: jsxs(Box, { background: 'none', children: [jsx(Typography, { mb: 1, variant: 'body1', sx: { color: theme.palette.info.dark }, children: fieldLabel }), jsx(PerspectiveClassField, { iconDetails: iconDetails, handleDelete: handleDelete, handleSelect: handleSelect, perspectiveClasses: perspectiveClasses, selectedPerspectiveClass: selectedPerspectiveClass })] }) }), jsxs(DialogActions, { children: [jsx(Button, { variant: 'contained', disabled: propertyValue === selectedPerspectiveClass || isLoading, onClick: () => onSave && onSave(selectedPerspectiveClass, propertyName), children: "Save" }), jsx(Button, { variant: 'outlined', onClick: onClose, children: "Cancel" })] })] }));
};

export { EditPerspectiveClassDialog };
