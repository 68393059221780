import { jsxs, Fragment, jsx } from 'react/jsx-runtime';
import { DetailsRow } from '../../DetailsRow.js';
import { Chip } from '../../chip/Chip.js';
import '@fluentui/react-icons';
import '../../../types/requestTypes.js';
import '../../../types/itemTypes.js';
import '@mui/material';
import 'react';
import '../../../themes/light.theme.js';
import '../../chip/ItemManagementStatusChip.js';
import '../../chip/PerspectiveClassChip.js';
import { Box } from '../../Box.js';
import '../../../config.js';
import '../../../auth/config.js';
import '../../../auth/AuthProvider.js';
import '@azure/msal-react';
import 'dayjs';
import 'react-redux';
import '@testing-library/react';
import '../../../store/store.js';
import 'redux-persist/integration/react';
import '../../../store/slices/applicationSlice.js';
import '../../../store/slices/downloadFileSlice.js';
import '../../../store/slices/itemModalSlice.js';
import '@pdftron/webviewer-video';
import '@pdftron/webviewer-audio';
import '../../../services/requestApi.js';
import '../../../services/userApi.js';
import { useGetEmailCopyQuery } from '../../../services/itemApi.js';
import '../../../services/requestDetailsApi.js';
import '@pdftron/webviewer';
import 'lodash';
import 'react-router-dom';
import '@dnd-kit/core';
import '@dnd-kit/sortable';
import '../../../store/thunks/downloadFileThunk.js';
import { UserChip } from '../../chip/UserChip.js';
import styled from '@emotion/styled';
import { AttachedItems } from './components/AttachedItems.js';

const StyledImage = styled('img') ``;
const ItemTypeEmailPanel = ({ item }) => {
    const itemType = item?.TypeDef?._Display;
    const { data: emailCopy } = useGetEmailCopyQuery({ itemId: item.ID });
    // TODO: Refactor this component, make the property names dynamic.
    return (jsxs(Fragment, { children: [jsx(DetailsRow, { label: 'Name', data: item?.DisplayName }, `${itemType}-name`), jsx(DetailsRow, { label: 'Subject', data: item?.Subject }, `${itemType}-subject`), jsx(DetailsRow, { label: 'From', data: item?.From?.DisplayName ? (jsx(UserChip, { user: { name: item?.From?.DisplayName } })) : null }, `${itemType}-from`), jsx(DetailsRow, { label: 'To', data: jsx(Box, { background: 'none', direction: 'column', children: item?.ToRecipients?.value?.map((user) => (jsx(UserChip, { user: { name: user?.DisplayName }, sx: { marginBottom: '0.5rem' } }))) }) }, `${itemType}-to`), jsx(DetailsRow, { label: 'CC', data: jsx(Box, { background: 'none', direction: 'column', children: item?.CCRecipients?.value?.map((user) => (jsx(UserChip, { user: { name: user?.DisplayName }, sx: { marginBottom: '0.5rem' } }))) }) }, `${itemType}-cc`), jsx(DetailsRow, { label: 'BCC', data: jsx(Box, { background: 'none', direction: 'column', children: item?.BCCRecipients?.value?.map((user) => (jsx(UserChip, { user: { name: user?.DisplayName }, sx: { marginBottom: '0.5rem' } }))) }) }, `${itemType}-bcc`), jsx(DetailsRow, { label: 'Attachments', data: jsx(Box, { background: 'none', direction: 'column', children: item?.AttachedItems?.value?.map((item) => (jsx(AttachedItems, { item: item }))) }) }, `${itemType}-attachments`), jsx(DetailsRow, { label: 'Copies', data: jsx(Box, { background: 'none', direction: 'column', children: emailCopy?.value?.map((item) => {
                        const parentFolder = item?.ParentFolder?.DisplayName;
                        const emailDetails = parentFolder === 'Inbox'
                            ? item?.Mailbox?.Owner
                            : item?.Email.From;
                        return (jsx(Box, { background: 'none', width: '100%', style: {
                                maxWidth: 'fit-content',
                            }, children: jsx(Chip, { icon: jsx(StyledImage, { src: `${process.env.REACT_APP_ENC_IMC_URL}email_1.svg`, style: { height: '1.5rem' } }), title: emailDetails?.DisplayName ?? 'No email address', subTitle: `${parentFolder} (${emailDetails?.Mail ?? 'No email address'})`, sx: { justifyContent: 'start', marginBottom: '0.5rem' } }) }));
                    }) }) }, `${itemType}-copies`)] }));
};

export { ItemTypeEmailPanel };
