import { jsx, jsxs } from 'react/jsx-runtime';
import { Box } from './Box.js';
import { ItemBusinessTypePanel } from './item/ItemBusinessTypePanel.js';
import '@mui/material';
import '@fluentui/react-icons';
import '../themes/light.theme.js';
import { ItemGovernancePanel } from './item/ItemGovernancePanel.js';
import { ItemTypePanel } from './item/ItemTypePanel.js';
import '../auth/config.js';
import '../config.js';
import '../auth/AuthProvider.js';
import '@azure/msal-react';
import 'react';
import '../types/requestTypes.js';
import '../types/itemTypes.js';
import 'dayjs';
import 'react-redux';
import '@testing-library/react';
import '../store/store.js';
import 'redux-persist/integration/react';
import '../store/slices/applicationSlice.js';
import '../store/slices/downloadFileSlice.js';
import '../store/slices/itemModalSlice.js';
import '@pdftron/webviewer-video';
import '@pdftron/webviewer-audio';
import '../services/requestApi.js';
import '../services/userApi.js';
import '../services/itemApi.js';
import '../services/requestDetailsApi.js';
import '@pdftron/webviewer';
import 'lodash';
import 'react-router-dom';
import '@dnd-kit/core';
import '@dnd-kit/sortable';
import '../store/thunks/downloadFileThunk.js';
import './chip/ItemManagementStatusChip.js';
import './chip/PerspectiveClassChip.js';
import './item/type/ItemTypeEmailPanel.js';

const ItemSummaryPanel = ({ item, disposalRequest, hasEditPermission, treeGridProperties, businessTypeProperties, businessTypeIconDetails, businessTypePropertyValues, onOpen, }) => {
    return (jsx(Box, { padding: 'none', background: 'none', direction: 'row', gap: 'none', height: '100%', children: jsxs(Box, { background: 'none', width: '100%', style: { overflow: 'overlay', paddingRight: '1rem', overflowY: 'auto' }, children: [jsx(ItemBusinessTypePanel, { item: item, hasEditPermission: hasEditPermission, disposalRequest: disposalRequest, treeGridProperties: treeGridProperties, businessTypeProperties: businessTypeProperties, businessTypeIconDetails: businessTypeIconDetails, businessTypePropertyValues: businessTypePropertyValues }), jsx(ItemTypePanel, { item: item }), jsx(ItemGovernancePanel, { item: item, disposalRequest: disposalRequest, hasEditPermission: hasEditPermission, onOpen: onOpen })] }) }));
};

export { ItemSummaryPanel };
